//@ts-check
angular.module('app').controller('AccountSelectCtrl', ["$scope", "$rootScope", "$timeout", "Server", "$state", "Util", "$window", function ($scope, $rootScope, $timeout, Server, $state, Util, $window) {

  $scope.accounts = [];
  $scope.searchQuery = '';

  // Hide the header/footer when entering the page
  let headerElement = null;
  let footerElement = null;

  $timeout(function() {
    headerElement = document.querySelector('header');
    footerElement = document.querySelector('footer');
    if (headerElement) {
      headerElement.style.display = 'none'; // Hide the header
    }
    if (footerElement) {
      footerElement.style.display = 'none'; // Hide the footer
    }
  }, 0);

  // Not sure about the code below, it's restoring the header/footer when leaving the page
  // but didn't test it.
  // Restore the header when leaving the page
  $scope.$on('$destroy', function() {
    if (headerElement) {
      headerElement.style.display = ''; // Restore the header display
    }
  });

  // Restore the footer when leaving the page
  $scope.$on('$destroy', function() {
    if (footerElement) {
      footerElement.style.display = ''; // Restore the footer display
    }
  });

  Server.get('users/me/multiacct')
  .then(function(response) {
    let users = [response.primaryUser, ...response.linkedAccountUsers];

    let accounts = users.map(user => {
      if(user.adminUser?.mainEmployerBranding) {
        return {
          id: user._id,
          company: user.adminUser.mainEmployerBranding.companyInformation.name || 'Unknown Company',
          companyLogo: user.adminUser.mainEmployerBranding.customization.logo || null,
          suspended: user.suspended
        };
      } else {
        return {
          id: user._id,
          company: user.mainEmployerBranding.companyInformation.name || 'Unknown Company',
          companyLogo: user.mainEmployerBranding.customization.logo || null,
          suspended: user.suspended
        };
      }
    });

    // Sort accounts by suspended status
    $scope.accounts = accounts.sort((a, b) => {
      if (a.suspended && !b.suspended) return 1;
      if (!a.suspended && b.suspended) return -1;
      return 0;
    });
  })
  .catch(function(err) {
    console.error('Error fetching accounts:', err);
  });

  // Select an account using API
  $scope.selectAccount = function(account) {
    if (!account.suspended && account.id) {
      Server.post('users/me/multiacct', { selectedAccountId: account.id })
        .then(function(response) {
          if (response && response.result === 1) {
            Server.get('users/me')
              .then(function(user) {
                $rootScope.user = user;
                $state.go('campaigns').then(() => {
                  $timeout(() => {
                    $rootScope.$apply();
                    $window.location.reload();
                  }, 0);
                })
              })
              .catch(function(err) {
                console.error('Error fetching user:', err);
              })
          } else {
            console.error('Failed to select account:', response);
          }
        })
        .catch(function(err) {
          console.error('Error selecting account:', err);
        });
    }
  };

  $scope.filteredAccounts = function() {
    const query = $scope.searchQuery.toLowerCase();
    const accounts = $scope.accounts;
  
    return accounts.filter(account => {
      const companyName = account.company.toLowerCase();
      return companyName.includes(query);
    });
};
  
    
    // Truncate long text
    $scope.truncateText = function(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    };


  }]);
  