//@ts-check
//@ts-ignore
DashboardAssessmentCtrl.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "Server", "Translate", "Util", "ToasterService", "overlaySpinner", "PopupService", "Onboarding", "multiSelect", "$timeout"];
angular.module('app').controller('DashboardAssessmentsCtrl', DashboardAssessmentCtrl);

/**
 * @param {DashboardAssessmentScope} $scope 
 */
function DashboardAssessmentCtrl($scope, $rootScope, $state, $stateParams, Server, Translate, Util, ToasterService, overlaySpinner, PopupService,Onboarding, multiSelect, $timeout) {
  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.hasPrivileges(['hasAssessments'], user)) {
      $state.go('campaigns');
    }
    if (!$rootScope.fns.userHasRights('assessments.list', 'view', user)) {
      $state.go('campaigns');
    }
  });

  Util.setPageTitle(`${Translate.getLangString('assessments')}`);
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;

  $scope.assessments = [];

  $scope.ddSortOptions = [
    {
        text: Translate.getLangString('default'),
        value: 'default',
        property: ['created'],
        reverse: true
    },
    {
        text: Translate.getLangString('campaign_sort_by_recent'),
        value: 'recent',
        property: 'created',
        reverse: true
    },
    {
        text: Translate.getLangString('campaign_sort_by_candidates'),
        value: 'totalcandidates',
        property: 'candidates',
        reverse: true
    },
    {
        text: Translate.getLangString('campaign_sort_by_alphabetic'),
        value: 'alphabetic',
        property: 'title',
        reverse: false
    }
  ];
  $scope.ddSortClick = function(selected) {
    $rootScope.assessmentSortProperty = selected.property;
    $rootScope.assessmentSortReverse = selected.reverse;
  };
  $scope.ddSortClick($scope.ddSortOptions[0]);

  $scope.ddFilterOptions = [
    {
        text: Translate.getLangString('assessment_filter_all'),
        value: 'all'
    },
    {
        text: Translate.getLangString('assessment_filter_active'),
        value: 'active'
    },
    {
        text: Translate.getLangString('assessment_filter_archived'),
        value: 'archived'
    }
  ];
  $scope.ddFilterSelected = $scope.ddFilterOptions[0];

  $scope.ddSettingsOptions = [
    { text: Translate.getLangString('edit_assessment'), value: 'edit' },
    $rootScope.fns.userHasRights("assessments.list", "edit") ? { text: Translate.getLangString('duplicate_assessment'), value: 'duplicate' } : null,
    $rootScope.fns.userHasRights("assessments.list", "delete") ? { text: Translate.getLangString('delete'), value: 'delete' } : null,
  ].filter(m => m)

  $scope.getAssessments = async function() {
    const overlay = overlaySpinner.show('assessment');
    try {
      const response = await Server.get('assessments?fetchCandidates=true');
      overlay.hide()
      $scope.assessments = response;

      let brandings = [];
      updateMultiselectButtonTextln();

      $scope.assessments.forEach(assessment => {
        if (assessment.customization && assessment.customization.employerBrandingId) {
          if (!brandings.find(b => b._id === assessment.customization.employerBrandingId._id)) {
              brandings.push({
                  id: assessment.customization.employerBrandingId._id,
                  _id: assessment.customization.employerBrandingId._id,
                  label: assessment.customization.employerBrandingId.name
              });
          }
      }
      })

      setBrandingOptions(brandings);

      $scope.$apply();
    } catch (err) {
      overlay.hide();
      ToasterService.failure(err, 'load_assessments_error');
    }
    
    return $scope.assessments;
  }
  
  $scope.ddSettingsClick = function(selected, assessmentId, assessment) {
    switch (selected.value) {

      case 'edit':
        // almost duplicate code (ref 2542)
        $state.go('assessment-edit', { assessmentId: assessmentId });
        break;

      case 'delete':
        // almost duplicate code (ref 2542)
        PopupService.openGenericPopup($scope, {
          submit: function () {
            $scope.modalHandle.close();
            Server.deleteObject('assessments', assessmentId)
              .then(function () {
                if ($state.is('assessments') && $scope.assessments) {
                    // the view is the list of assessments
                    _.remove($scope.assessments, a => a._id === assessmentId);
                } else {
                    // the view is the list of candidates
                    $state.go('assessments');
                }
              });
            },
            title: Translate.getLangString('delete_confirmation_title'),
            warningText: Translate.getLangString('delete_confirmation_warning'),
            yesText: Translate.getLangString('delete'),
            noText: Translate.getLangString('cancel')
          }, 'templates/modal-confirm-warning.html', {});
          break;
      case 'duplicate':
        // almost duplicate code (ref 2542)
        Server.post('assessments/' + assessmentId + '/duplicate')
          .then(function (newAssessment) {
              if ($state.is('assessments') && $scope.assessments) {
                  $scope.assessments.push(newAssessment);
                  // $rootScope.$emit('campaignsList.onCampaignsData');
              } else {
                  // the view is the list of candidates
                  $state.go('assessment-candidates', { assessmentId: newAssessment._id });
              }
          })
          .catch(function (err) {
              ToasterService.failure(err, 'err_35b_duplicating_assessment');
          });
        break;
    }
  }


  // Options
  $scope.brandingOptions = [];
  $scope.languageOptions = [];
  $scope.allLanguages = Translate.getLangDropdownObject().map(l => ({ id: l.value, label: l.full }));

  function setBrandingOptions(brandings) {
    $scope.brandingOptions = brandings.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
  }

  $scope.searchField = '';
  $scope.search = { brandings: [], language: []};

  // Multiselect - Translations
  let multiSelectln = JSON.parse(JSON.stringify(multiSelect))
  $scope.multiselectTextsBranding = { ...multiSelectln.texts, buttonDefaultText: Translate.getLangString('brand')};
  $scope.multiselectSettingsln = { ...multiSelectln.objectSettings, idProperty: 'id' };
  $scope.multiselectTextsln = multiSelectln.texts;
  $scope.readyTobuildMultiSelectln = false;

  function updateMultiselectButtonTextln() {
    $scope.multiselectTextsln.buttonDefaultText = Translate.getLangString('career_search_language');
    $scope.readyTobuildMultiSelectln = false;
    $timeout(function () {
        $scope.readyTobuildMultiSelectln = true;
    }, 10);
  }


  // Filters
  $scope.filterFn = function (assessment) {
    return textFilter(assessment) && brandingFilter(assessment) && languageFilter(assessment);
  };

  function brandingFilter(assessment) {
    if ($scope.search.brandings.length === 0) {
        return true;
    }
    return $scope.search.brandings.some(filter => {
        return assessment.customization
          && assessment.customization.employerBrandingId
          && assessment.customization.employerBrandingId._id === filter._id;
    });
}
  function languageFilter(assessment) {
    var checkedLanguagesIds = _.map($scope.search.language, 'id');
    if (!checkedLanguagesIds || !checkedLanguagesIds.length)
        return true;
    return _.includes(checkedLanguagesIds, assessment.language);
  }
  
function textFilter(assessment) {
  return Util.userSearch(
      [],
      [assessment.title],
      $scope.searchField
  );
}

  Onboarding.initWidget('dashboard-assessments');
  
  $scope.getAssessments();
}

/**
 * @typedef DashboardAssessmentScope
 * @property { Assessment[] } assessments
 * @property { () => Promise<Assessment[]> } getAssessments
 * @property { SortOption[] } ddSortOptions
 * @property { ({text: string, value: any}|null)[] } ddSettingsOptions
 * @property { {text: string, value: any}[] } ddFilterOptions
 * @property { {text: string, value: any} } ddFilterSelected
 * @property { (selected: SortOption) => void } ddSortClick
 * @property { (selected: any, assessmentId: string, assessment: Assessment) => void } ddSettingsClick
 * @property { any } modalHandle
 * @property { () => void } $apply
 * 
 * // Change the type of the following properties to the correct type later (Greg)
 * @property { any } brandingOptions
 * @property { any } languageOptions
 * @property { any } filterFn
 * @property { any } allLanguages
 * @property { any } multiselectSettingsln
 * @property { any } search
 * @property { string } searchField
 * @property { any } multiselectTextsBranding
 * @property { any } multiselectTextsln
 * @property { boolean } readyTobuildMultiSelectln
 * @property { any } ddSortSelected
 */

/**
 * @typedef Assessment
 * @property { string } _id
 * @property { any } title
 * @property { any } description
 * @property { Date } [created]
 * @property { Number } language
 * @property { any[] } candidates
 * @property { any[] } assignedCollaborators virtual field for used only on the frontend and to send a list of collaborators on assessment creation
 * @property { { employerBrandingId: string }} [customization]
 */

/**
 * @typedef AssessmentCandidate
 * @property { string } _id
 * @property { string } title
 * @property { string } description
 * @property { Date } created
 */

/**
 * @typedef SortOption
 * @property { string } text
 * @property { any } value
 * @property { string|string[]|(()=>string) } property
 * @property { boolean } reverse
 */